import Container from "@mui/material/Container";
import MuiLink from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Box, styled } from "@mui/material";
// components
import Iconify from "@/Components/iconify/Iconify";
import GuestHeader from "./user/Header/GuestHeader";

const CircleSocialIcon = styled(MuiLink)(({ theme }) => ({
    cursor: "pointer",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    transition: theme.transitions.create("all", {
        duration: 300,
        easing: "easeInOut",
    }),
    color: "#fff",
    "&:hover": {
        backgroundColor: theme.palette.primary.light,
    },
}));

export default function LandingLayout({ children }) {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            {/* HEADER */}
            <GuestHeader />
            {/* END HEADER */}

            {/* MAIN CONTENT */}
            <Box component="main" sx={{ flexGrow: 1, mt: 12 }}>
                {children}
            </Box>

            {/* FOOTER */}
            <Paper elevation={3} sx={{ p: 3.5, backgroundColor: "#f1f7fd", mt:20 }}>
                <Container>
                    <Stack
                        direction="row"
                        alignItems="center"
                        flexWrap="wrap"
                        sx={{
                            justifyContent: {
                                sm: "center",
                                xs: "center",
                                md: "space-between",
                            },
                        }}
                        spacing={5}
                    >
                        <Stack>
                            <Typography variant="body2" gutterBottom>
                                &#169; Copyright <b>Upcare</b>. All Rights Reserved
                            </Typography>
                            <Typography variant="caption">
                                Designed by{" "}
                                <MuiLink target="_blank" rel="nofollow noopener" href="https://infinityhub.com/">
                                    InfinityHub
                                </MuiLink>
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <CircleSocialIcon href="#"><Iconify icon="mdi:twitter" /></CircleSocialIcon>
                            <CircleSocialIcon href="https://www.facebook.com/Universalpinoycare">
                                <Iconify icon="ri:facebook-fill" />
                            </CircleSocialIcon>
                            <CircleSocialIcon href="#"><Iconify icon="ri:instagram-fill" /></CircleSocialIcon>
                            <CircleSocialIcon href="#"><Iconify icon="mdi:skype" /></CircleSocialIcon>
                            <CircleSocialIcon href="#"><Iconify icon="mdi:linkedin" /></CircleSocialIcon>
                        </Stack>
                    </Stack>
                </Container>
            </Paper>
            {/* END FOOTER */}
        </Box>
    );
}

